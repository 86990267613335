import * as React from "react"
import Seo from "../components/seo"
import SiteMapPage from "../components/OtherPages/SiteMap"

const SiteMap = () => (
  <>
    <Seo 
      title="HTML-карта сайта Рефинжиниринг (https://refeng.ru)" 
      description="HTML карта сайта ООО Рефинжиниринг поможет быстро найти интересующую вас страницу"
    />
    <SiteMapPage />
  </>
)

export default SiteMap
