import React from "react";
import SiteMapHtml from './sitemaphtml'


const SiteMap = () => (

  <>
    <SiteMapHtml />
  </>
)

export default SiteMap