import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
// import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';


const SiteMapHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
                <Row>
                    <Col xl={8}>
                        <h1>Карта сайта:</h1>
                    </Col>
                </Row>
            </Container>
                <div className="sitemap">
                    <Container fluid>
                        <Row>

                        {/* ПЕРВЫЙ СТОЛБЕЦ  */}
                            <Col>
                                <h2>Рефинжиниринг</h2>
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot-sitemap">
                                        <a href="/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Главная страница
                                        </a>
                                        </li>
                                        <li class="post-li-dot-sitemap">
                                            <a href="https://drive.google.com/file/d/1fQfPH4WjuW_kXzY0PdB2a5zbVR2n2bmf/view?usp=sharing" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Презентация компании
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="https://drive.google.com/file/d/15PUYtDDog3jecrC2azXY6ob7OBcZ-Po6/view?usp=sharing" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Сертификаты СРО
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/sout" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                СОУТ
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/offer" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Вакансии
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/contacts" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Контакты
                                            </a>
                                        </li>
                                    </ul>
                            </Col>

                            {/* ВТОРОЙ СТОЛБЕЦ */}
                            <Col>
                                <h2>Услуги</h2>
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot-sitemap">
                                        <a href="/kontzeptziya-proekta" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Разработка концепции проекта холодоснабжения
                                        </a>
                                        </li>
                                        <li class="post-li-dot-sitemap">
                                            <a href="/teh-obosnovanie" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Предварительный экспертный расчет
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/oformlenie-dokumentatzii" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Разработка полного комплекта документации
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/proizvodstvo-oborudovaniya" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Свое производство
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/upravlenie-proektom" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Комплексное планирование
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/service-guarantee" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Круглосуточная сервисная служба
                                            </a>
                                        </li>
                                    </ul>
                            </Col>

                            {/* ТРЕТИЙ СТОЛБЕЦ */}
                            <Col xl={2}>
                                <h2>Партнеры</h2>
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot-sitemap">
                                            <a href="/partners/bitzer" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                BITZER
                                            </a>
                                        </li>
                                        <li class="post-li-dot-sitemap">
                                            <a href="/partners/bock" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                BOCK
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/partners/grundfos" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Grundfos
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/partners/guntner" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Guntner
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/partners/danfoss" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Danfoss
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/partners/carel" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Carel
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/partners/abb" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ABB
                                            </a>
                                        </li>
                                    </ul>
                            </Col>

                            {/* ЧЕТВЕРТЫЙ СТОЛБЕЦ */}
                            <Col xl={2}>
                                <h2>Соцсети:</h2>
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot">
                                            <a href="https://www.instagram.com/refengineering/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Instagram
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="https://www.facebook.com/refengineering/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                Facebook
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="https://www.youtube.com/channel/UCEa9t_Pn4q5Iz17rvd1tEcA" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                YouTube
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="https://vk.com/refengineering" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ВКонтакте
                                            </a>
                                        </li>
                                    </ul>
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <h2>Компетенции</h2>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                                
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot-sitemap">
                                        <a href="/kontzeptziya-proekta" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Охлаждение и заморозка
                                        </a>
                                        <ul class="post-ul-dot">
                                                <li class="post-li-dot">
                                                    <a href="/shokovaya-zamorozka/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Камера шоковой заморозки
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/tunnelnaya/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Туннельная заморозка
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/fluidizatzionnaya/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Флюидизационная заморозка
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/spiralnye-apparaty/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Cпиральный конвейер для шоковой заморозки
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/plitochnaya/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Cкороморозильные плиточные аппараты
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="post-li-dot-sitemap">
                                        <a href="/ohlajdaemye-sklady/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Охлаждаемые склады
                                        </a>
                                        <ul class="post-ul-dot">
                                                <li class="post-li-dot">
                                                    <a href="/hranenie-produktov/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Для хранения пищевых продуктов
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/hranenie-plodoovoshoi-produktzii/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Камеры для хранения овощей и фруктов
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/kamery-gazatzii/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Камеры газации бананов
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/gazovie-sredy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Регулируемая газовая среда
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/hranenie-medikamentov/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Хранение медикаментов на складе
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                    </ul>
                            </Col>
                            <Col>
                                <ul class="post-ul-dot">
                                    
                                <li class="post-li-dot-sitemap">
                                        <a href="/klimat-kamery/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Климатические камеры
                                        </a>
                                        <ul class="post-ul-dot">
                                                <li class="post-li-dot">
                                                    <a href="/viderjka-kolbas" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Климатическая камера для колбас
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/klimat-dlya-syrov" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Камера для созревания сыра
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/dozarivanie-plodov" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Камеры дозаривания плодов
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    <li class="post-li-dot">
                                        <a href="/konditzionirovanie-proizvodstv/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Вентиляция и кондиционирование производственных помещений
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/ohlajdenie-jidkostei/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Охлаждение жидкостей
                                        </a>
                                        <ul class="post-ul-dot">
                                                <li class="post-li-dot">
                                                    <a href="/ohladitel-moloka/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Молокоохладители
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/chillery-pryamoe-kipenie/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Чиллеры с прямым кипением хладагента
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/drycoolery/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Сухие охладители (драйкуллеры)
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/isparitelnoe-ohlajdenie/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Испарительное охлаждение
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        
                                </ul>
                            </Col>
                            <Col>
                                <ul class="post-ul-dot">
                                <li class="post-li-dot-sitemap">
                                        <a href="/ice-water/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Ледяная вода
                                        </a>
                                        <ul class="post-ul-dot">
                                            <li class="post-li-dot">
                                                <a href="/akkumulyatory-lda/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                    Льдоаккумуляторы
                                                </a>
                                            </li>
                                            <li class="post-li-dot">
                                                <a href="/chiller-dlya-ohlajdeniya-vody/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                    Чиллер с промежуточным хладоносителем
                                                </a>
                                            </li>
                                            <li class="post-li-dot">
                                                <a href="/zatoplennyi-isparitel/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                    Затопленный испаритель
                                                </a>
                                            </li>
                                            <li class="post-li-dot">
                                                <a href="/gybridnye-skhemy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                    Гибридные схемы
                                                </a>
                                            </li>
                                        </ul>
                                    </li>

                                        <li class="post-li-dot-sitemap">
                                        <a href="/ldogeneratory/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Льдогенераторы
                                        </a>
                                        <ul class="post-ul-dot">
                                                <li class="post-li-dot">
                                                    <a href="/ldogenerator-cheshuichatogo-lda/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Льдогенератор чешуйчатого льда
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/granulirovanniy-led/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Льдогенератор гранулированного льда
                                                    </a>
                                                </li>
                                                <li class="post-li-dot">
                                                    <a href="/jidkiy-led/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                        Льдогенератор жидкого льда
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    <li class="post-li-dot">
                                        <a href="/recuperatciya/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Рекуперация тепла для технологических нужд и отопления
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>

                        {/* ВСЕ ПРОЕКТЫ: */}

                        <Row>
                            <Col>
                                <h2>Реализованные проекты (Референс)</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ul class="post-ul-dot">
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/agrokombinat-moskovskiy" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Агрокомбинат «Московский»
                                        </a>
                                    </li>

                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/bimbo/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            ООО «БИМБО КьюЭсАр Рус»
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/obninskiy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            МПК «Обнинский»
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                    <a href="/cherkizovo" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ГК «Черкизово»
                                    </a>
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/bikom-ammiachnie-ustanovki/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ОАО «Биком». Техническое перевооружение АХУ.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/bikom-cherkizovo/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ОАО «Биком». Камера хранения готовой продукции.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/lisko-broiler-cherkizovo/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ООО «Лиско Бройлер». Производство мяса цыплят бройлеров.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/petelinka/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                АО «Петелинская Птицефабрика»
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/mosselprom/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                АО «Моссельпром»
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/kurinoe-tzarstvo/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                АО «Куриное Царство — Брянск»
                                            </a>
                                        </li>
                                    </ul>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                    <a href="/ozeretzkiy-kombinat/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        «Озерецкий молочный комбинат». ТМ «ЭКОМИЛК».
                                    </a>
                                    <ul class="post-ul-dot">
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/ecomilk-syr/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ООО «Озерецкий сыродельный комбинат». Цех по выпуску сыров.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/novosibirskiy-molochniy-kombinat/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ООО «Новосибирский молочный комбинат». Модульная система холодоснабжения.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/mk-ozeretzkiy-modul/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Модульный хладоцентр.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/mk-ozeretzkiy-holod-kombinatorov/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Производственный комплекс (маслоцех).
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/new-chiller/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Новый чиллер.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/ohlajdenie-pet/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Система охлаждения линии ПЭТ.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/mk-ozeretzkiy-holod-zony-otgruzki/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Зона отгрузки молочной продукции.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/nizkotemperaturnaya-kamera/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ООО «Новосибирский молочный комбинат». Низкотемпературная камера.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/mk-ozeretzkiy-reconstruktziya-systemy-ledyanoi-vody/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Системы ледяной воды.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/mk-ozeretzkiy-reconstruktziya-system-holodosnabjeniya/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Реконструкция системы холодоснабжения.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/zao-ozeretzkii-suhoe-moloko/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                ЗАО «Озерецкий МК». Камера хранения сухого молока.
                                            </a>
                                        </li>
                                        <li class="post-li-dot">
                                            <a href="/realizovannye_proekty/biocad/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                АО «BIOCAD». Производственно-складской комплекс фармакологической продукции.
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                </ul>
                            </Col>
                            <Col>
                            <ul class="post-ul-dot">
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/foodcode" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Сапсан Технологический Комплекс». Производство замороженных хлебобулочных изделий.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/novorosslogistics/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Новоросслогистик». Холодильный перегрузочный терминал.
                                    </a>
                                </li>

                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/sj-distributorskiy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Сладкая Жизнь». Системы холодоснабжения ТРЦ.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/sladkaya-jizn-rybniy-tzeh/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Сладкая Жизнь». Система промышленного кондиционирования рыбного цеха.
                                    </a>
                                </li>

                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/koptilnoe-proizvodstvo-sweet-life/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Сладкая Жизнь». Система холодоснабжения коптильных камер.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/kolomenskiy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        Хлебный завод «Коломенский». Системы холодоснабжения.
                                    </a>
                                </li>

                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/milkprom/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Милкпром». Новое сырное производство.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/cheese-kaluga/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                    «MOLOKO GROUP». Производство сыра в Калуге.
                                    </a>
                                </li>

                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/proectinvest/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                    «MOLOKO GROUP». Производство и реализация сыров в г.Москва.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/prodinvest/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        «BOSCO DI CHILIEGI». Колбасное производство.
                                    </a>
                                </li>

                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/penza-cheese/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Метромаркет». Новое производство сырной продукции г.Пенза.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/klinskiy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        Мясокомбинат «Клинский». Система холодоснабжения.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/vilon-novaya-shokkamera/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «ТПК ВИЛОН». Камера шоковой заморозки до 800 кг/час.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/vilon-nani/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        «Вилон» - «NANI». Зоны отгрузки готовой продукции.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/apikur-trk-novomoskovskiy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ТРК «Новомосковский». Системы кондиционирования.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/chicken-factory/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Чикен Фэктори». Системы холодоснабжения и вентиляции.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/rosinter/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ОАО «Росинтер Ресторантс Холдинг». Системы холодоснабжения для камеры шоковой заморозки и камер хранения, системы вентиляции.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/agrotip-belorechenskiy/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ТК «Белореченский». Системы холодоснабжения для камер охлаждения/хранения и отделения перегрузки.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/goldregion/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Золотой регион». Камера шоковой заморозки фарша -35˚С. Камера хранения готовой продукции -22˚С.
                                    </a>
                                </li>
                                <li class="post-li-dot-sitemap">
                                    <a href="/realizovannye_proekty/nsk/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                        ООО «Новая Строительная Компания». Системы холодоснабжения для шести холодильных камер -22˚С.
                                    </a>
                                </li>
                                </ul>
                            </Col>
                            <Col>
                                <ul class="post-ul-dot">
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/goldenfish-transservice/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            ООО «Голден ФИШ» и ООО «Морская Компания «Транс-сервис». Системы охлаждения морской воды для рыболовных кораблей.
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/stroytekhmarket/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            ООО «Стройтехмаркет». Системы холодоснабжения для холодильных камер и производственных помещений.
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/fromageamour/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            «Fromage Amour» (Фромаж Амур). Камера созревания сыров. Проектирование и поставка климатических систем микроклимата (холодоснабжение, осушка, вентиляция, увлажнение).
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/aldini/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Завод по производству сыра «Альдини». Две камеры созревания сыра Горгонзола (два этапа созревания).
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/veststroi/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            ООО «ВЕСТСТРОЙ». Частная сыроварня. Камера охлаждения молока и камера созревания сыров.
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/grandlaitier/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Сыродельный завод «Гран Летье». Проектирование Технологии производства французских сыров «Пале», «Сент-Марселен», «Шаурс».
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/adamantstroi-euroros/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            ООО «Адамант-Строй». Проектирование Технологии и Систем Холодоснабжения для Гипермаркета и центрального производства ТС «Евророс», зоны фудкорта, консешн-бара и ресторана.
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/luberetzkie-torgovie-ryady/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            «Люберецкие торговые ряды». Проектирование Систем Холодоснабжения для фермерского рынка с торговой площадью 3’200м² и вспомогательными площадями 2’300м².
                                        </a>
                                    </li>
                                    <li class="post-li-dot-sitemap">
                                        <a href="/realizovannye_proekty/gup-fhu-kompleksa-arhitektury/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            ГУП «ФХУ Комплекса архитектуры, строительства, развития и реконструкции города». Проектирование отопления, вентиляции и кондиционирования.
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>

                        {/* КОНЕЦ ВСЕ ПРОЕКТЫ */}

                        {/* НАЧАЛО БЛОГ */}

                        <Row>
                            <Col>
                                <h2>Публикации</h2>
                                <ul class="post-ul-dot">
                                    <li class="post-li-dot-sitemap">
                                        <a href="/blog" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Публикации
                                        </a>
                                        <ul class="post-ul-dot">
                                            <li class="post-li-dot">
                                                <a href="/blog/zapret-freonov/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                    Запрет фреонов. 
                                                </a>
                                            </li>
                                            <li class="post-li-dot">
                                                <a href="/blog/ammiachnye-holodilnye-ustanovki/" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                                    Аммиачные холодильные установки.
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Col>
                            {/* КОНЕЦ БЛОГА */}

                            {/* НАЧАЛО ОБОРУДОВАНИЕ ПРОИЗВОДСТВА */}
                            <Col>
                                <h2>Оборудование</h2>
                                <ul class="post-ul-dot">
                                    <li class="post-li-dot-sitemap">
                                        <a href="/equipment/kkb" rel="noopener noreferrer" target = "_blank"  aria-label="Главная страница">
                                            Компактные компрессорно-конденсаторные блоки (ККБ).
                                        </a>
                                    </li>
                                </ul>
                            </Col>

                            {/* КОНЕЦ ОБОРУДОВАНИЕ ПРОИЗВОДСТВА */}

                            <Col>
                            
                            </Col>

                        </Row>

                    </Container>
                </div>
            <Footer />
        </>
    )
}

export default SiteMapHtml
